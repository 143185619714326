import { default as _91_46_46_46slug_93XCgNlrOoyLMeta } from "/cac/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93gsdnMP1U8bMeta } from "/cac/pages/iframe/[...slug].vue?macro=true";
import { default as searchr62GaCByi4Meta } from "/cac/pages/iframe/search.vue?macro=true";
import { default as _91_46_46_46slug_932A7EA3QiyHMeta } from "/cac/pages/preview/[...slug].vue?macro=true";
import { default as tnewCTC6xU6SBLMeta } from "/cac/pages/tnew.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/cac/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "iframe-slug",
    path: "/iframe/:slug(.*)*",
    meta: _91_46_46_46slug_93gsdnMP1U8bMeta || {},
    component: () => import("/cac/pages/iframe/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "iframe-search",
    path: "/iframe/search",
    meta: searchr62GaCByi4Meta || {},
    component: () => import("/cac/pages/iframe/search.vue").then(m => m.default || m)
  },
  {
    name: "preview-slug",
    path: "/preview/:slug(.*)*",
    component: () => import("/cac/pages/preview/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "tnew",
    path: "/tnew",
    component: () => import("/cac/pages/tnew.vue").then(m => m.default || m)
  }
]