export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("focus", {
    mounted(el, binding, vnode) {
      let hasFocus = el.contains(document.activeElement);
      if (typeof binding.value === "function") binding.value(hasFocus);

      document.addEventListener("focusin", () => {
        hasFocus = el.contains(document.activeElement);
        if (typeof binding.value === "function") binding.value(hasFocus);
      });
    },
    beforeUnmount(el) {
      document.removeEventListener("focusin", () => {});
    },
  });
});
