import type { RouterOptions } from '@nuxt/schema';

export default <RouterOptions>{
	// Force page routes to pull the user to the top of the page
	scrollBehavior(to, from, savedPosition) {
		if (to?.hash) {
			return {
				el: to.hash,
				top: 150,
				behavior: 'smooth',
			};
		} else {
			return {
				top: 0,
			};
		}
	},
};
