import revive_payload_client_4sVQNw7RlN from "/cac/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/cac/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/cac/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/cac/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/cac/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/cac/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/cac/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/cac/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/cac/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/cac/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/cac/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/cac/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_BqLkNUef68 from "/cac/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import autoRun_cDZyfSziwA from "/cac/node_modules/@tubstrr/seo/dist/runtime/autoRun.mjs";
import animateOnScroll_C9LsKpZhSC from "/cac/plugins/animateOnScroll.js";
import bus_RrsLoRnN1Z from "/cac/plugins/bus.ts";
import focus_9NFqPXxCey from "/cac/plugins/focus.ts";
import hidden_c7RXeslJKl from "/cac/plugins/hidden.js";
import sentry_PZVGm6LH3G from "/cac/plugins/sentry.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_BqLkNUef68,
  autoRun_cDZyfSziwA,
  animateOnScroll_C9LsKpZhSC,
  bus_RrsLoRnN1Z,
  focus_9NFqPXxCey,
  hidden_c7RXeslJKl,
  sentry_PZVGm6LH3G
]