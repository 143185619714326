import { getEnv } from '@/composables/helpers/getEnv';
import { redirect } from '@/composables/helpers/redirect';
export default defineNuxtRouteMiddleware(async (to, from) => {
 const builderApi = getEnv('builderApi');
 let altPath = to.path + '/';
 if (to.path.endsWith('/')) altPath = to.path.slice(0, -1);

 const response = await $fetch('https://cdn.builder.io/api/v3/content/redirects', {
  method: 'GET',
  headers: {
   Accept: '*/*',
  },
  query: {
   apiKey: builderApi,
   'query.data.target.$or': [to.path || '', altPath || ''],
   //  'query.data.target': to.path || '',
   limit: '1',
   fields: 'data',
   cacheSeconds: 1,
  },
 });

 if (response?.results?.length) {
  const result = response.results[0].data;
  redirect({
   url: result.destination || '/',
   status: result.code || 301,
  });
 }
});
