import { testInternalLink, formatUrl } from '~/composables/helpers/links';
import { useAPI } from '~/composables/data/useAPI';
import { getEnv } from '@/composables/helpers/getEnv';

export const redirect = async (data: RedirectOptions) => {
 if (!data) return;
 if (!data.url) return;
 const internalLink = testInternalLink(data.url);

 if (internalLink) {
  const url = formatUrl(data.url, data.url.startsWith('/'));
  if (process.client) {
   await navigateTo(url, {
    redirectCode: data.status | 301,
    replace: true,
   });
   await reloadNuxtApp({
    force: true,
    persistState: true,
   });
  }
 } else {
  if (process.client) {
   await navigateTo(data.url, {
    redirectCode: data.status | 301,
    replace: true,
    external: true,
   });
  }
 }
};

export const checkRedirects = async (path: string) => {
 // Refactor this to use $fetch
 let redirects = {} as redirectAPI;
 if (process.client) {
  redirects = (await useAPI('content/redirects', {
   params: {
    'query.data.target': path,
    fields: 'name,data',
   },
  })) as redirectAPI;
 } else {
  const builderApi = getEnv('builderApi');
  redirects = await $fetch(`https://cdn.builder.io/api/v3/content/redirects`, {
   params: {
    apiKey: builderApi,
    'query.data.target': path,
    'builder.cachebust': 'true',
    'builder.noCache': 'true',
    cacheSeconds: 1,
   },
  });
 }

 if (redirects?.results?.length) {
  const result = redirects.results[0].data;
  const data = {
   url: result.destination,
   status: result.code,
   raw: redirects.results,
  };
  if (process.server) return data;

  await redirect(data);
  return true;
 }
 return false;
};
