export default defineNuxtRouteMiddleware((to, from) => {
	if (process.client && to && from) {
		const path_to = to?.href.split('?')[0].split('#')[0];
		const path_from = from?.href.split('?')[0].split('#')[0];
		const samePage = path_to === path_from;

		if (!samePage) {
			const { $bus } = useNuxtApp();
			$bus.emit('scroll-lock');
		}
	}
});
