import validate from "/cac/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/cac/middleware/redirect.global.js";
import reset_45window_45global from "/cac/middleware/resetWindow.global.js";
import manifest_45route_45rule from "/cac/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  reset_45window_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}