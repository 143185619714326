export default defineNuxtPlugin((nuxtApp) => {
	if (process.server) {
		nuxtApp.vueApp.directive('aos', {
			beforeMount(el) {
				el.classList.add('v-aos');
				el.classList.add('v-aos-before-enter');
			},
		});
	} else {
		const options = {
			threshold: 0.5,
		};
		const animateOnScrollObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.remove('v-aos-before-enter');
					entry.target.classList.add('v-aos-entered');
				}
			});
		}, options);

		const newIO = (el, threshold) => {
			const animateOnScrollObserver = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							entry.target.classList.remove('v-aos-before-enter');
							entry.target.classList.add('v-aos-entered');
						}
					});
				},
				{ threshold }
			);
			animateOnScrollObserver.observe(el);
		};

		nuxtApp.vueApp.directive('aos', {
			mounted(el, binding) {
				el.classList.add('v-aos');
				el.classList.add('v-aos-before-enter');
				if (binding.value) {
					newIO(el, binding.value);
				} else {
					animateOnScrollObserver.observe(el);
				}
			},
		});
	}
});
