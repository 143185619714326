const setDisplay = (el, value) => {
 if (value) {
  el.classList.add('v-hidden');
  el.setAttribute('tabindex', '-1');
  el.setAttribute('aria-hidden', 'true');
 } else {
  el.classList.remove('v-hidden');
  el.removeAttribute('tabindex');
  el.removeAttribute('aria-hidden');
  el.setAttribute('aria-live', 'polite');
  el.removeAttribute('tabindex');
  el.setAttribute('aria-hidden', 'false');
 }
};

export default defineNuxtPlugin((nuxtApp) => {
 nuxtApp.vueApp.directive('hidden', {
  beforeMount(el, { value }, { transition }) {
   if (transition && !value) {
    transition.beforeEnter(el);
   } else {
    setDisplay(el, value);
   }
  },
  mounted(el, { value }, { transition }) {
   if (transition && !value) {
    transition.enter(el);
   }
  },
  updated(el, { value, oldValue }, { transition }) {
   if (value === oldValue) return;
   if (transition) {
    if (!value) {
     transition.beforeEnter(el);
     setDisplay(el, false);
     transition.enter(el);
    } else {
     transition.leave(el, () => {
      setDisplay(el, true);
     });
    }
   } else {
    setDisplay(el, value);
   }
  },
  beforeUnmount(el, { value }) {
   setDisplay(el, value);
  },
 });
});
