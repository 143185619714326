<script setup>
	// Libraries
	import { storeToRefs } from 'pinia';

	// Stores
	import { useGlobalStore } from '@/stores/global';
	const { errorImages } = storeToRefs(useGlobalStore());

	// Composables
	import { initializeLayout } from '@/composables/helpers/layouts';

	// Props
	defineProps(['error']);

	// Variables
	const { $bus } = useNuxtApp();
	const query = ref('');
	const searchOpen = ref(false);
	const is404 = useState('is404', () => true);

	// Methods
	const openSearch = () => {
		$bus.emit('scroll-lock', { search: true });

		searchOpen.value = true;
		setTimeout(() => {
			$bus.emit('open-search');
			query.value = '';
		}, 32);
	};
	const closeSearch = () => {
		$bus.emit('scroll-unlock');
		searchOpen.value = false;
	};

	// Life cycle
	await initializeLayout();

	//  $bus.emit('404-page', true);
	onMounted(() => {
		is404.value = true;
		// $bus.emit('404-page', true);
	});
</script>

<template>
	<div class="root-wrapper">
		<GlobalHeader />
		<section id="four-oh-four-page" class="page-wrapper">
			<main class="error-page">
				<div class="content-wrapper container">
					<img
						v-if="errorImages?.desktopImage"
						:src="errorImages?.desktopImage"
						class="background-image desktop"
						:alt="errorImages.altTag"
						loading="lazy"
					/>
					<img
						v-if="errorImages?.mobileImage"
						:src="errorImages?.mobileImage"
						class="background-image mobile"
						:alt="errorImages.altTag"
						loading="lazy"
					/>
					<div class="inner-wrapper">
						<ProjectTitle :title="'' + error.statusCode" tag="h1" class="container" />
						<div class="error-copy">
							<h2 class="h4" v-if="error.statusCode == 404">We can't find the page you're looking for.</h2>
							<h2 class="h4" v-else>Something went wrong.</h2>

							<div class="buttons-wrapper">
								<a href="/" class="mess-button base fs-12 project-button red">
									<span>Go Home</span>
								</a>
								<button @click="openSearch" class="mess-button base fs-12 project-button red">Search</button>
							</div>
							<ProjectModal :opened="searchOpen" @close="closeSearch" :classes="'search-modal'">
								<GlobalAlgolia />
							</ProjectModal>
						</div>
					</div>
				</div>
			</main>
		</section>
		<GlobalFooter ref="footerWrapper" />
	</div>
</template>

<style lang="scss">
	#four-oh-four-page {
		.mobile {
			display: none;
		}
		.content-wrapper {
			position: relative;

			display: flex;
			align-items: center;
			justify-content: center;

			text-align: center;

			min-height: calc(var(--vh) * 50);
			min-width: calc(var(--vw) * 100);

			aspect-ratio: 1 / 0.5;

			.background-image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: -1;
			}
			.inner-wrapper {
				@media (min-width: $mobile) {
					display: grid;
					grid-template-columns: repeat(12, calc(8.33% - 20px));
					grid-template-rows: 25px 25px 25px 25px 25px 25px 25px auto;
					grid-gap: 0 20px;
				}

				.title-wrapper {
					@media (min-width: $mobile) {
						width: auto;
						grid-column: 5 / span 2;
						grid-row: 1 / span 4;
					}

					h1 {
						margin-bottom: 30px;
						font-size: 100px;

						@media (min-width: $mobile) {
							margin-bottom: 0;
							font-size: 100px;
						}
						@media (min-width: $tablet) {
							font-size: 130px;
						}
					}
				}

				.error-copy {
					@media (min-width: $mobile) {
						width: auto;
						grid-column: 6 / span 6;
						grid-row: 6 / span max-content;
						text-align: left;
					}

					@media (min-width: $tablet) {
						width: auto;
						grid-column: 6 / span 7;
						grid-row: 7 / span max-content;
					}

					h2 {
						margin-top: 0;
					}

					.buttons-wrapper {
						margin-top: 24px;
						display: flex;
						gap: 2rem;
						justify-content: center;

						@media (min-width: $tablet) {
							flex-direction: row;
							justify-content: flex-start;
						}

						.project-button span {
							font-size: inherit;
							font-weight: 900;
							text-transform: uppercase;
							line-height: 1.231;
							letter-spacing: 1.38px;
							color: inherit;
							text-decoration: none;
						}

						a.project-button,
						button.project-button {
							text-decoration: none;
							text-transform: uppercase;
							font-weight: 900;
							width: fit-content;

							&:visited {
								border-bottom: 1px solid var(--red);
								&:hover {
									border-bottom: 1px solid var(--white);
								}
							}
						}
					}
				}
			}

			.button {
				margin-top: 25px;
			}
		}

		@media (max-width: $mobile) {
			.mobile {
				display: block;
			}
			.desktop {
				display: none;
			}
			.content-wrapper {
				aspect-ratio: 0.8/1;
			}
		}
	}
</style>
